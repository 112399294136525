.App {
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed;
  top: 3rem;
  margin: auto;
  left: 0;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
}