.sidebar {
  width: 13rem;
  display: block;
  margin: auto;
  flex-direction: column;
  background: #212d38c2;
  text-align: center;
  position: fixed;
  top: 2rem;
  height: 84vh;
  opacity: 0;
  right: -11rem;
  transition: all 0.6s ease;
  color: white;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -1px 0 4px 0px #000000;
}


.sidebar.open {
  opacity: 1;
  right: 0;
}

.sidebar.close{
  opacity: 0;
  right: -11rem;
}




.title-sidebar {
  position: relative;
  width: 100%;
  height: 28px;
  background: var(--warna-1);
  font-family: "kanit" , Sans-Serif;
  color: white;
  font-size: 18px;
  user-select: none;
  text-align: center;
  align-items: center;
}





.content-media-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -10px;
}
.content-toko-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -18px;
}
.content-web-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -15px;
}



.content-sidebar {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 3px;
  margin: 3px;
  width: 100%;
  height: 28px;
  transition: all 0.4s ease;
}
.icons-sidebar {
  font-size: 26px;
  color: white;
}
.icons-sidebar-img {
  width: 26px;
  font-size: 26px;
  background: white;
  color: white;
  flex-direction: row;
  padding: 1px;
  border-radius: 5px;
}

.teks-produk {
  color: white;
  font-size: 18px;
  text-align: start;
  display: flex;
  position: relative;
  margin: auto;
  margin-left: 8px;
  margin-top: -2px;
  font-family: "kanit", Sans-Serif;
  font-weight: 300;
}
Link, a{
  color :black;
  text-decoration: none;
  text-decoration-line: none;
}

.content-sidebar:hover {
  background:#162e429b ;
  transform: scale(1.03);
  border-bottom: 1px solid white;
}





.sidebar::-webkit-scrollbar {
    width: 5px;
}

.sidebar::-webkit-scrollbar-track {
    background: var(--warna-1);
}

.sidebar::-webkit-scrollbar-thumb {
    background: white;
}