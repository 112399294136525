.header {
  width: 100%;
  height: 3rem;
  background: var(--warna-1) ;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
}
.logo {
  position: relative;
  top: 4px;
  width: 50px;
  height: 50px;
}
.btnside {
  position: relative;
  top: 4px;
  font-size: 25px;
  background: none;
  border: none;
  color: white;
}
